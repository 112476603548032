import * as React from 'react'

import { ChakraProvider } from '@chakra-ui/react'

function App() {
  return (
    <ChakraProvider>
      <p>Stryde Admin Frontend</p>
      <p>Environment: {process.env.STRYDE_ADMIN_ENVIRONMENT}</p>
    </ChakraProvider>
  )
}
export default App;